<template v-if="formActive">

  <table>
  <thead class="mb-2 bborder-bottom fw-bold">
    <tr>
      <td>
        <FormItem id="upol" v-model="allPorts" type="checkbox" @onchange="(e) => reverseSelection(e)" />
      </td>
      <td>
        <span style="position: relative; top: 2px">{{$t('navigation.ports')}}</span>&ensp;
        <button type="button" class="btn p-0 btn-link text-black" @click="setPortOrder">{{$t('manifest.switch')}}</button>
      </td>
      <td></td>
      <td class="text-center">B/Ls</td>
      <td class="text-center">Printed</td>
      <td>Customer</td>
      <td>Status</td>
      <td>Type</td>
      <td colspan="2">Approval</td>
    </tr>
  </thead>
  <template v-if="chosenPorts.length > 0 && !listLoading">
    <template v-for="pol in (reversePortOrder ? chosenPortsReverse : chosenPorts)" :key="pol.id">
      <tr class="data-tr" :class="{'published': pol.status == 'published'}">
        <td>
          <FormItem :id="'pol-'+pol.id" v-model="pol.chosen" type="checkbox" @onchange="(e) => setChosenPOLs(e, pol)" />
        </td>
        <td class="pt-1 fw-bold"> <label :for="'pol-'+pol.id">{{pol.name}}</label> </td>
        <td class="pt-1"> </td>
        <td class="pt-1 text-center">
          <i v-show="pol.approved_count == pol.bl_count" class="far fa-check-circle text-success"></i> 
          <span v-show="pol.approved_count != pol.bl_count">{{pol.approved_count + ' / ' + pol.bl_count}}</span> 
        </td>
        <td class="pt-1" colspan="6"> </td>
      </tr>
      <template v-for="pod in pol.pods" :key="pod.id">
        <tr class="data-tr" :class="{'published': pod.status == 'published'}">
          <td class="pe-0" style="padding-top: 2px">
            <FormItem :id="'pol-'+pol.id+'-'+pod.id" v-model="pod.chosen" type="checkbox" @onchange="(e) => setChosenPODs(e, pod, pol)" />
          </td>
          <td>
            <button :id="'btn-'+pol.id+'-'+pod.id" class="btn p-0 shadow-none" type="button" style="position: relative; bottom: 2px" @click="pod.expanded = !pod.expanded">
              <i class="fa fa-chevron-right" :style="{'rotate': (pod.expanded ? '90deg' : '0deg')}"></i>
            </button>
            <label class="col-form-label auto-check-label ps-2 pt-1 pb-0 fw-bold" :for="'pol-'+pol.id+'-'+pod.id">{{pod.name}}</label>
          </td>
          <td>
            <div class="col-form-label-sm" v-if="pod.status == 'published'">
              <span>Published: {{pod.publish_info}}</span>&nbsp;
              <span class="float-right btn btn-sm p-0 btn-link" @click="unpublish(pol, pod)">{{$t('form.undo')}}</span>
            </div>
            <div v-else>
              <FormItem :id="'qstatus-'+pol.id+'-'+pod.id" type="radiogroup-status" v-model="pod.status" sizeItem="12" @onclick="(e) => publish(e, pol, pod)" :disabled="voyage.own_bls_only"
                        :options="[{id: 'open', name: 'Open'}, {id: 'closed', name: 'Closed'}, {id: 'published', name: 'Published', disabled: (pod.approved_count < pod.bl_count || pod.loaded_count < pod.bl_count)}]" />
            </div>
          </td>
          <td class="pt-1 text-center">
            <i v-show="pod.approved_count == pod.bl_count" class="far fa-check-circle text-success"></i> 
            <span v-show="pod.approved_count != pod.bl_count">{{pod.approved_count + ' / ' + pod.bl_count}}</span> 
          </td>
          <td colspan="6"> </td>
        </tr>
        <tbody class="collapse" :id="'collapse-' +pol.id + '-' + pod.id" :class="{'show': pod.expanded}">
          <tr class="data-tr hoverable" v-for="bl in pod.bills_of_lading" :key="bl.id" :class="{'bg-approved': bl.supervisor_approval_id == 4, 'bg-declined': bl.supervisor_approval_id == 3}" @mousedown="setSelectedBL(pol, pod, bl)">
            <td class="pe-0">
              <FormItem :id="'bl-'+bl.id+'-'+pol.id+'-'+pod.id" v-model="bl.chosen" type="checkbox" @onchange="(e) => setChosenBLs(e, pol, pod, bl)" />
            </td>
            <td style="padding-left: 2rem">
              <label class="col-form-label auto-check-label ps-1" :for="'bl-'+bl.id+'-'+pol.id+'-'+pod.id">{{bl.name}}</label>
            </td>
            <td class="" :title="'operated by: ' + (bl.operator ? bl.operator : '-')">{{bl.office ? bl.office : bl.operator + '*'}} </td>
            <td class=""> <a class="clickable pt-1 ps-1 show-on-hover" @click="openBL(bl)">Open B/L</a> </td>
            <td class="text-center"> <i class="far text-success pt-1" :class="{'fa-check-circle': bl.printed}"></i> </td>
            <td class="text-truncate"> {{bl.customer}} </td>
            <td> {{ $t('bl_status.' + bl.bill_of_lading_status_id).toLowerCase() }} </td>
            <td class=""> {{bl.type ? bl.type.toLowerCase() : '-'}} </td>
            <td class="d-flex pt-1">
              <input :id="'blstatus-'+bl.id+'-'+pol.id+'-'+pod.id" class="form-check-input mt-2" v-model="bl.supervisor_approved" type="checkbox" @change="setAccepted(bl, pol, pod)" :disabled="!voyage.can_approve_own" />
              <label :for="'blstatus-'+bl.id+'-'+pol.id+'-'+pod.id" class="pt-1 ps-2">{{approvalStatuses[bl.supervisor_approval_id]}}</label>
              <i class="far fa-comment pt-2 ps-2" :style="{visibility: (bl.has_comments ? 'visible' : 'hidden')}"></i>
              <i class="far fa-warning text-danger pt-2 ps-1" :style="{visibility: (bl.force_final ? 'visible' : 'hidden')}"></i>
            </td>
            <td> <a class="clickable pt-1 ps-3 show-on-hover" @click="openStatusModel">status</a> </td>
          </tr>
        </tbody>
      </template>
    </template>

  </template>
  </table>

  <div class="p-3">
    <p v-show="chosenPorts.length == 0">{{ $t('form.no_found', [$tc('navigation.bills_of_lading', 2)]) }}</p>
    <div :class="{ 'd-none': !listLoading }"> <i class="fas fa-spinner fa-spin"></i> {{$t('form.please_wait')}} </div>
  </div>

  <dialog id="status-dialog">
    <form method="dialog" class="w-100">
      <div class="modal-header w-100 pt-3 pb-2">
        <h5 style="font-size: 1.2rem !important" class="ps-2">Approval status for B/L {{selectedBL.name}}</h5>
        <button id="fakeCloseBtn2" class="btn-close btn-black" aria-label="Close"></button>
      </div>
      <div class="p-4">
        <div>Status</div>
        <div class="w-50 mb-2">
            <FormItem id="tab_id" v-model="statusData.tabType" type="radiogroup" sizeItem="12" :options="tabTypes" @onchange="setTabTypes" :disabled="!voyage.can_approve_own" />
        </div>
        <div class="mt-3">Supervisor ({{operatedByOffice ? operatedByOffice.name : ''}})</div>
        <div class="w-50">
          <FormItem id="supcomm" v-model="statusData.supervisorComments" type="textarea" sizeItem="12" :disabled="!canEdit && operatedByOffice.id != currentOffice" options="3" />
        </div>
        <div class="mt-3">{{selectedBL.customer}}</div>
        <div class="w-50">
          <FormItem id="nmtcomm" v-model="statusData.nmtComments" type="textarea" sizeItem="12" options="3" />
        </div>
        <button class="btn btn-success mt-4 px-3" @click="setStatusRequest">{{ $t('form.save') }}</button>
      </div>
    </form>
  </dialog>

  <dialog id="warning-dialog">
    <form method="dialog" class="w-100">
      <div class="warning-header w-100 pt-3 pb-2">
        <h5 style="font-size: 1.2rem !important" class="ps-2">Warning!</h5>
        <button id="fakeCloseBtn4" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="p-4">
        <p class="mb-2"> {{$t('manifest.warning_publish_1', [forcePublishData.amount])}} </p>
        <div class="d-flex gap-2">
          <FormItem id="forcepublish" v-model="forcePublish" type="checkbox" />
          <label for="forcepublish"> {{$t('manifest.warning_publish_2', [forcePublishData.amount, forcePublishData.voyageport])}} </label>
        </div>
        <button class="btn btn-danger mt-4 px-3" :disabled="!forcePublish" @click="publish({}, forcePublishData.pol, forcePublishData.pod, true)">{{ $t('form.continue') }}</button>
      </div>
    </form>
  </dialog>

  <dialog id="job-dialog">
    <form method="dialog" style="max-width: 1400px">
      <JobForm ref="jobRef" :itemData="jobItemData" :fromExport="true" />
    </form>
  </dialog>
  <dialog id="bl-dialog">
    <form method="dialog" style="max-width: 1400px">
      <div class="modal-header w-100 pt-3 pb-2">
        <h5 style="font-size: 1.2rem !important" class="ps-2" id="detailsHeaderAbove">{{selectedBL.name}}</h5>
        <button id="fakeCloseBtn3" class="btn-close btn-black" aria-label="Close"></button>
      </div>
      <div class="p-4" style="height: calc(100vh - 250px); overflow-y: auto">
        <BLComponent :itemData="blItemData" ref="blRef" />
      </div>
      <div class="d-flex justify-content-end gap-3 pt-3 pb-3 pe-4 me-3 border-top">
        <FormItem type="download" :label="$t('form.save_download')" startTag="fal fa-file-pdf" :service="saveBL" :functionArgs="[true]" />
        <FormItem :options="{send: true}" startTag="fa fa-save" :service="saveBL" type="download" :label="$t('form.save')" />
      </div>
    </form>
  </dialog>

</template>

<script>
  import voyageService from '@/services/VoyageService';
  import voyageManifestService from '@/services/VoyageManifestService';
  import blService from '@/services/BillOfLadingService';
  import jobService from '@/services/JobService';
  import JobForm from '@/components/jobs/JobFormNew.vue';
  import BLComponent from '@/components/jobs/BillOfLadingFormNew.vue';
  import moment from 'moment';
  import store from '@/store/user';
  export default {
     watch: {
      itemData: function (val) {
        this.voyage = val    
      },
    },
    components: {
      JobForm,
      BLComponent
    },
    data() {
      return {
        itemLoaded: false,
        listLoading: false,
        voyage: { ports: [] },
        allPorts: 0,
        nmtCompany: 1,
        reversePortOrder: false,
        hasChannelSet: false,
        bookedByOfficeId: null,
        formatType: 1,
        manifestType: 3,
        chosenPorts: [],
        chosenPortsReverse: [],
        chosenPols: [],
        chosenPods: [],
        chosenBLs: [],
        transitBLs: [],
        operatedByOffice: {},
        allBLs: {},
        selectedBL: {id: null},
        statusData: {},
        portsIdCode: {},
        blItemData: {},
        jobItemData: {},
        savedCheckData: null,
        downloadType: 2,
        forcePublish: 0,
        forcePublishData: {},
        transitOptions: [],
        transitOptionsObj: {},
        hasSaywayBill: false,
        extraOptions: {"shipper": 1, "units": 1, "tabs": 1, "dimensions": 1, "format": 1, "relations": 1, "show_description": 0, "bltype": 1, "background": 1, "zipped": 1, "redtext": 0, "canada": 0, "consignee": 1, "show_blnumber": 0, "split_transit": 0},
        manifestTypes: [{id: 1, name: 'Normal'}, {id: 2, name: 'Measurement'}, {id: 3, name: 'Agent freight'}, {id: 4, name: 'Carrier Freight'},
                        {id: 5, name: '(XML) Namibia'}, {id: 6, name: '(XML) Balloré'}, {id: 7, name: '(XML) GovCBR'}, {id: 8, name: 'Bills of Lading'}],
        formatTypes: [{id: 1, name: 'Preview'}, {id: 2, name: 'Download PDF'}, {id: 3, name: 'Download CSV'}, {id: 4, name: 'Download Excel'}],
        formatTypesBL: [{id: 1, name: 'PDF'}, {id: 2, name: 'Zip File'}],
        formatTypesXML: [{id: 1, name: 'XML'}],
        tabTypes: [{id: 1, name: 'Unspecified'}, {id: 4, name: 'Approved'}, {id: 3, name: 'Declined'}, {id: 2, name: 'Requested'}],
        approvalStatuses: ['', 'unspecified', 'requested', 'declined', 'approved'],
        formActive: false
      };
    },

    methods: {
      active(value){
          this.formActive = value;
      },
      validate(){
        return false;
      },
      getData() {
        return this.voyage
      },
      filterByOperator(option, name){
        this.operatedByOfficeId = option;
        const id = this.voyage.id + 1 - 1;
        this.voyage = {};
        this.reset(this.userIsOperator);
        this.listLoading = true;
        voyageManifestService.show(id, this.operatedByOfficeId).then(response => this.setData(response.data));
      },
      filterByBooker(option, name){
        this.bookedByOfficeId = option;
        const id = this.voyage.id + 1 - 1;
        this.voyage = {};
        this.reset(this.userIsOperator, this.bookedByOfficeId);
        this.listLoading = true;
        voyageManifestService.show(id, this.operatedByOfficeId, this.bookedByOfficeId).then(response => this.setData(response.data));
      },
      resetAfterJobChanges(){
        const id = this.voyage.id + 1 - 1;
        this.voyage = {};
        this.reset();
        let expansions = [];
        (this.reversePortOrder ? this.chosenPortsReverse : this.chosenPorts).forEach(pol => {
          pol.pods.forEach(pod => {
            if(pod.expanded) expansions.push(pod.id);
          });
        });
        this.savedCheckData = {bls: [...this.chosenBLs], expanded: expansions};
        this.listLoading = true;
        voyageManifestService.show(id, this.operatedByOfficeId).then(response => this.setData(response.data));
      },
      reset(userIsOperator = false, bookedByOfficeId = null){
        this.$parent.$parent.$parent.reset(userIsOperator);
        this.$refs.jobRef.resetMPStatus = false;
        this.hasChannelSet = false;
        this.bookedByOfficeId = bookedByOfficeId;
        this.statusData = {};
        this.savedCheckData = null;
        this.canEdit = store.getters.getCompanyHq || store.getters.getDocumentationClearance ? true : false;
        this.currentOffice = store.getters.getCompanyId;
        this.allPorts = 0;
      },
      setData(data) {
        this.voyage = data;
        this.chosenPorts = [];
        this.chosenPortsReverse = [];
        this.chosenPols = [];
        this.chosenPods = [];
        this.chosenVPods = [];
        this.chosenBLs = [];
        this.allBLs = {};
        this.transitOptionsObj = {};
        this.operatedByOptions = data.operators;
        this.bookedByOptions = data.booked_offices;
        this.operatedByOptions.forEach(opt => opt.name = opt.relation.name);
        this.operatedByOptions.sort((a, b) => a.name > b.name ? 1 : -1);
        this.operatedByOfficeId = Number(data.operated_by_office_id);
        this.operatedByOffice = data.operators.find(op => op.id == this.operatedByOfficeId);
        this.bookedByOfficeId = data.booked_by_office_id;
        this.userIsOperator = data.is_operator;
        if(!this.operatedByOffice) this.operatedByOffice = data.operators[0];
        if(!this.operatedByOffice) this.operatedByOffice = {
          id: this.operatedByOfficeId,
          name: data.operator_name
        };
        
        const parent = this.$parent.$parent.$parent;
        parent.operatedByOptions = this.operatedByOptions;
        parent.operatedByOfficeId = data.is_default_operator ? null : this.operatedByOfficeId;
        
        if (this.operatedByOptions.length === 1) {
          this.operatedByOffice = this.operatedByOptions[0];
          this.operatedByOfficeId = this.operatedByOffice.id;
          parent.operatedByOfficeId = this.operatedByOfficeId;
        }
        parent.bookedByOptions = this.bookedByOptions;
        parent.bookedByOfficeId = this.bookedByOfficeId;
        parent.userIsOperator = data.is_operator;
        parent.$forceUpdate();
        this.listLoading = false;
        if(!this.hasChannelSet){
          this.formatType = 1;
          this.manifestType = 3;
          this.reversePortOrder = false;
          const el = document.getElementById('detailsSpan');
          if(el){
            el.innerHTML = data.name;
            this.$nextTick(() => el.previousElementSibling.innerHTML = "");
          }
        }
        for(let pol in data.ports){
          const newpol = data.ports[pol];
          this.portsIdCode[newpol.id] = newpol.code;
          let blcount = 0;
          let approvedcount = 0;
          for(let pod in newpol.pods){
            const newpod = newpol.pods[pod];
            blcount += newpod.bills_of_lading.length;
            approvedcount += newpod.bills_of_lading.filter(bl => bl.supervisor_approval_id == 4).length;
          }
          let newPOLIndex = this.chosenPorts.push({
            id: newpol.id,
            name: newpol.name,
            chosen: false,
            idcode: pol,
            code: newpol.code,
            pods: [],
            bl_count: blcount,
            approved_count: approvedcount
          });
          this.voyage.ports[pol].BLsByNumber = [];
          for(let pod in newpol.pods){
            const newpod = newpol.pods[pod];
            this.portsIdCode[newpod.id] = newpod.code;
            let commstatus = this.voyage.voyage_port_statuses.find(cs => (cs.port_of_loading_id == newpol.id && cs.port_of_discharge_id == newpod.id && cs.office_id == this.operatedByOfficeId));
            if(!commstatus) commstatus = this.voyage.voyage_port_statuses.find(cs => (cs.port_of_loading_id === null && cs.port_of_discharge_id == newpod.id && cs.office_id == this.operatedByOfficeId));
            if(!commstatus){
              const newstatusindex = this.voyage.voyage_port_statuses.push({
                commodity_id: null,
                port_of_discharge_id: newpod.id,
                port_of_loading_id: newpol.id,
                published_by_user_id: null,
                published_date: null,
                status: 'open',
                voyage_id: this.voyage.id,
                office_id: this.operatedByOfficeId
              });
              commstatus = this.voyage.voyage_port_statuses[newstatusindex - 1];
            }
            const blApprovedCount = newpod.bills_of_lading.filter(bl => bl.supervisor_approval_id == 4).length;
            let newPODIndex = this.chosenPorts[newPOLIndex - 1].pods.push({
              id: newpod.id,
              name: newpod.name,
              chosen: false,
              expanded: false,
              idcode: pod,
              code: newpod.code,
              bl_count: newpod.bills_of_lading.length,
              status: commstatus ? commstatus.status : 'open',
              publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
              approved_count: blApprovedCount,
              bills_of_lading: []
            });
            let foundPortIndex = this.chosenPortsReverse.findIndex(port => port.id === newpod.id);
            let foundPortSubIndex = 0;
            if(foundPortIndex >= 0){
              foundPortSubIndex = this.chosenPortsReverse[foundPortIndex].pods.push({ 
                id: newpol.id, 
                name: newpol.name, 
                chosen: false, 
                idcode: pol,
                code: newpol.code,
                bills_of_lading: [],
                bl_count: newpod.bills_of_lading.length,
                status: commstatus ? commstatus.status : 'open',
                publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
                approved_count: blApprovedCount,
              }) - 1;
              this.chosenPortsReverse[foundPortIndex].approved_count += blApprovedCount;
              this.chosenPortsReverse[foundPortIndex].bl_count += newpod.bills_of_lading.length;
            }
            else{
              foundPortIndex = this.chosenPortsReverse.push({
                id: newpod.id,
                name: newpod.name,
                chosen: false,
                idcode: pod,
                code: newpod.code,
                bl_count: newpod.bills_of_lading.length,
                status: commstatus ? commstatus.status : 'open',
                publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
                approved_count: blApprovedCount,
                pods: [{ 
                  id: newpol.id, 
                  name: newpol.name, 
                  chosen: false,
                  idcode: pol,
                  code: newpol.code,
                  bills_of_lading: [],
                  bl_count: newpod.bills_of_lading.length,
                  status: commstatus ? commstatus.status : 'open',
                  publish_info: commstatus && commstatus.published_date ? (this.setPublishedDateString(commstatus.published_date) + ' - ' + commstatus.user.name) : '',
                  approved_count: blApprovedCount,
                }]
              }) - 1;
            }
            newpod.bills_of_lading.forEach(bl => {
              this.allBLs[bl.id] = bl;
              this.chosenPorts[newPOLIndex - 1].pods[newPODIndex - 1].bills_of_lading.push(this.setBLInfo(bl));
              this.chosenPortsReverse[foundPortIndex].pods[foundPortSubIndex].bills_of_lading.push(this.setBLInfo(bl));
              if(!bl.job_booking_voyage_manifest){
                bl.job_booking_voyage_manifest = {
                  bill_of_lading_id: bl.id,
                  is_approved_by_supervisor: false,
                  is_consolidated: false,
                  is_nmt_bl: false,
                  job_booking_voyage_id: bl.job_booking_id,
                  bill_of_lading_status_id: bl.bill_of_lading_status_id,
                  supervisor_approval_id: bl.supervisor_approval_id
                }
              }
              this.voyage.ports[pol].BLsByNumber.push({
                id: bl.id,
                form_id: newpod.id + '-' + bl.id,
                job_booking_voyage_manifest: bl.job_booking_voyage_manifest,
                number: bl.number,
                booking_index: Number(bl.booking_number.slice(-2)) - 1,
                pod_id: newpod.id,
                job_id: bl.job_id,
                job_booking_id: bl.job_booking_id,
                unit_count: bl.unit_count,
                pol: bl.pol,
                pod: bl.pod,
                code: newpod.code,
                loaded_count: bl.loaded_count,
                office: bl.office ? bl.office : '-',
                is_accepted: bl.bill_of_lading_status_id == 3,
                status: commstatus.status,
                bill_of_lading_status_id: bl.bill_of_lading_status_id,
                supervisor_approval_id: bl.supervisor_approval_id,
                customer: (bl.customer && bl.customer.name) ? bl.customer.name : bl.customer_name,
                operated_by_office_id: bl.operated_by_office_id
              });
              if(bl.in_transit && bl.country){
                this.transitOptionsObj[bl.country_id] = bl.country;
              }
            });
          }
          this.voyage.ports[pol].BLsByNumber.sort((a,b) => { return a.number > b.number ? 1 : -1 });
        }

        this.transitOptions = [];
        for(let key in this.transitOptionsObj){
          this.transitOptions.push(this.transitOptionsObj[key]);
        }
        this.transitOptions.sort((a, b) => a.name > b.name);
        this.transitOptions.splice(0, 0, {id: false, name: '-'});
        this.$parent.$parent.$parent.transitOptions = this.transitOptions;

        if(!this.hasChannelSet){
          window.activeChannel = `return-manifest-${this.voyage.id}`;
          window.Echo.channel(window.activeChannel)
            .listen('.listen-manifest', (e) => {
              console.log('broadcast', e);
              let foundPOL = this.chosenPorts.find(port => port.id == e.portOfLoadingId);
              if(foundPOL){
                foundPOL.approved_count += (e.checklistValue ? 1 : -1);
                let foundPOD = foundPOL.pods.find(port => port.id == e.portOfDischargeId);
                if(foundPOD){
                  foundPOD.approved_count += (e.checklistValue ? 1 : -1);
                }
                let foundBL = this.voyage.ports[foundPOL.idcode].BLsByNumber.find(bl => bl.job_booking_voyage_manifest.id == e.checklistId);
                if(foundBL){
                  foundBL.job_booking_voyage_manifest.is_approved_by_supervisor = e.checklistValue;
                }
              }
          });
          window.Echo.channel(`return-export-print-${store.getters.userId}`)
            .listen('.listen-export-print', (e) => {
              this.$parent.$parent.$parent.progress = e.progress;
          });
          this.hasChannelSet = true;
        }
        if(this.savedCheckData){
          this.chosenBLs = this.savedCheckData.bls;
          this.setChosenPOLPODs();
          this.checkForDrafts();
          (this.reversePortOrder ? this.chosenPortsReverse : this.chosenPorts).forEach(pol => {
            pol.pods.forEach(pod => pod.expanded = this.savedCheckData.expanded.includes(pod.id));
          });
          this.savedCheckData = null;
        }
      },
      setBLInfo(bl){
        return {
          chosen: this.savedCheckData ? (this.savedCheckData.bls.includes(bl.id) ? 1 : 0) : 0,
          id: bl.id,
          booking_id: bl.job_booking_id,
          job_id: bl.job_booking ? bl.job_booking.job_id : null,
          name: bl.number,
          vpod: bl.vpod,
          is_carrier_bl: !bl.job_booking_voyage_manifest.is_nmt_bl,
          type: bl.bl_type,
          unit_count: bl.unit_count,
          loaded_count: bl.loaded_count,
          customer: bl.customer_name ? bl.customer_name : (bl.customer ? bl.customer : '-'),
          printed: bl.original_downloaded,
          office: bl.office,
          operator: bl.operated_by_office ? bl.operated_by_office.relation.name : '-',
          branch: bl.branch,
          supervisor_approval_id: bl.supervisor_approval_id,
          supervisor_approved: bl.supervisor_approval_id == 4,
          supervisor_comment: bl.supervisor_comment,
          nmtcompany_comment: bl.nmtcompany_comment,
          has_comments: (bl.supervisor_comment || bl.nmtcompany_comment) ? true : false,
          bill_of_lading_status_id: bl.bill_of_lading_status_id,
          transit: bl.in_transit ? bl.country_id : 0,
          force_final: bl.force_final,
          operated_by_office_id: bl.operated_by_office_id
        }
      },

      setPublishedDateString(date){
        const dateItem = date ? new Date(date) : new Date();
        return new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short', hour12: false }).format(dateItem).replace(" at ", " ");
      },
      getIndex(){
        console.log('need this here because of many job calls');
      },

      generate(){
        this.setChosenPOLPODs();
        const overview = this.$parent.$parent.$parent;
        overview.itemLoading = true;
        if(this.downloadType == 2){
          this.downloadAllBLs();
          return;
        }
        this.itemLoaded = false;
        const filetypeindex = this.downloadType == 3 ? (this.extraOptions.tabs == 3 ? 4 : 3) : this.formatType;
        const download_types = ['', 'MANIFEST', 'BL', 'REQUEST'];
        const format = ['', 'PDF', 'CSV', 'EXCEL', 'EXCEL'];
        const manifest_type = ['CARRIER_BL_REQUEST', 'NORMAL', 'MEASUREMENT', 'AGENT_FREIGHT', 'CARRIER_FREIGHT', 'XML_NAMIBIA', 'XML_BOLORE', 'XML_GOVCBR', 'ASM_FILING', 'VINS_LIST', 'PRELOAD_CARGO'];
        const filename_type = ['blrequest', 'normal', 'measurement', 'agentfreight', 'carrierfreight', 'namibia', 'bolore', 'govcbr', 'amsfiling', 'vinslist', 'preloadcargo'];
        const format_name = (!(this.downloadType == 3 || this.manifestType <= 4 || this.manifestType > 7) && this.manifestType > 4) ? 'XML' : format[filetypeindex];
        let selectedBLs = [...this.chosenBLs];
        this.transitBLs = [];
        const countriesById = {};
        let transitCountryId = 0;
        let transitName = '';
        if(this.extraOptions.split_transit){
          selectedBLs.forEach(blid => {
            if(this.allBLs[blid].in_transit){
              this.transitBLs.push(blid);
              transitCountryId = this.allBLs[blid].country_id;
              if(!countriesById[transitCountryId]) countriesById[transitCountryId] = [];
              countriesById[transitCountryId].push(blid);
            }
          });
        }
        let canZIP = download_types[this.downloadType] == 'MANIFEST' && [1,2,3,4,10].includes(this.manifestType);
        let manifestAsZIP = canZIP && overview.transitOption;
        if(manifestAsZIP){
          this.extraOptions.zipped = 2;
          transitName = this.transitOptions.find(to => to.id == overview.transitOption).name.replaceAll(' ', '_');
          this.extraOptions.transit_name = (transitName + '').toUpperCase();
        }
        else if(!overview.transitOption && this.transitBLs.length > 0 && this.chosenPods.length == 1 && canZIP){
          manifestAsZIP = true;
          this.extraOptions.zipped = 2;
          this.extraOptions.transit_name = []
          for(let cId in countriesById){
            transitName = this.transitOptions.find(to => to.id == cId).name.replaceAll(' ', '_');
            this.extraOptions.transit_name.push({name: (transitName + '').toUpperCase(), ids: countriesById[cId]});
          }
        }
        else{
          if(canZIP){
            this.extraOptions.zipped = 1;
          }
          this.transitBLs = [];
        }
        let extraOptions = {...this.extraOptions};
        if(this.transitBLs.length > 0 && canZIP){
          selectedBLs = selectedBLs.filter(bl => !this.transitBLs.includes(bl));
        }
        let transitOnly = selectedBLs.length == 0 && this.transitBLs.length > 0;
        voyageService.showManifestPost(this.voyage.id, this.chosenPols, this.chosenVPods, selectedBLs, this.transitBLs, download_types[this.downloadType], format_name, manifest_type[this.manifestType], extraOptions).then(response => {
          let polstring = '';
          let podstring = '';
          let polpodstring = '';
          if(this.chosenPols.length < 5){
            this.chosenPols.forEach(id => {
              polstring += this.portsIdCode[id].toLowerCase();
            });
          }
          else{
            polstring = 'multiplepol';
          }
          this.chosenPods.forEach(id => {
            const code = this.portsIdCode[id].toLowerCase();
            if(!podstring.includes(code)) podstring += code;
          });
          if(podstring.length > 13){
            podstring = 'multiplepod';
          }
          polpodstring = this.chosenPols.length >= 5 && podstring.length > 13 ? '' : (polstring + '_' + podstring);
          const fielnameindex = this.downloadType == 3 ? ('_' + filename_type[0]) : ((this.manifestType == 3 || this.manifestType == 4) ? '_FRT' : '');
          const fileTypes = ['', '.pdf', '.csv', '.xlsx', '.zip'];
          const isForXML = this.manifestType > 4 && this.manifestType <= 7 && this.downloadType != 3;
          let filename = (this.voyage.number + '_' + polpodstring + fielnameindex).toUpperCase() + (isForXML ? '.xml' : fileTypes[filetypeindex]);
          if(manifestAsZIP){
            filename = (this.voyage.number + '_' + polpodstring + (transitOnly ? ('_TS_' + transitName) : '')).toUpperCase() + '.zip';
          }
          else if(download_types[this.downloadType] == 'MANIFEST' && format_name == "EXCEL"){
            filename = "EXCEL " + polpodstring.replaceAll('_', '').toUpperCase() + fileTypes[filetypeindex];
          }
          else if(download_types[this.downloadType] == 'MANIFEST' && fielnameindex == '_FRT'){
            filename = (polpodstring + fielnameindex).toUpperCase() + fileTypes[filetypeindex];
          }
          this.triggerDownload(response.data, filename);
          this.itemLoaded = this.formatType == 1;
          overview.itemLoading = false;
        }).catch(error => {
            this.toastError(error)
            console.log('error', error) // create notification-toaster for user error
            overview.itemLoading = false;
        });
      },

      downloadAllBLs() {
        const zipped = this.extraOptions.zipped == 2;
        const isorginal = Number(this.extraOptions.background) != 1 && Number(this.extraOptions.bltype) == 2;
        if(isorginal || this.hasSaywayBill){
          this.chosenPorts.forEach(pol => {
            pol.pods.forEach(pod => {
              pod.bills_of_lading.forEach(bl => {
                if(this.chosenBLs.includes(bl.id) && (isorginal || (bl.type == 'Sea Waybill' && bl.supervisor_approval_id == 4)))
                  bl.printed = 1;
              });
            });
          });
        }
        return voyageService.showAllBLs(this.chosenBLs, zipped, this.extraOptions).then(response => {
            const name = this.chosenBLs.length == 1 ? (this.allBLs[this.chosenBLs[0]].number) : (this.voyage.number + "_bl_summaries");
            this.triggerDownload(response.data, name + (zipped ? '.zip' : '.pdf'));
            this.$parent.$parent.$parent.itemLoading = false;
        }).catch(error => {
            this.toastError(error);
            this.$parent.$parent.$parent.itemLoading = false;
        });
      },

      setChosenPOLs(e, port){
        this.setChosenPOLPODs();
        const chosen = this.reversePortOrder ? this.chosenPods : this.chosenPols;
        const otherChosen = this.reversePortOrder ? this.chosenPols : this.chosenPods;
        this.allPorts = 0;
        if(e.target.checked){
          if(!chosen.includes(port.id)) chosen.push(port.id);
          port.hasOne = true;
          port.pods.forEach(pod => {
            if(!pod.chosen){
              pod.chosen = 1;
            }
            pod.bills_of_lading.forEach(bl => {
              if(!bl.chosen){
                bl.chosen = 1;
                this.chosenBLs.push(bl.id);
              }
            });
          });
        }
        else{
          port.hasOne = false;
          const index = chosen.findIndex(ch => ch == port.id);
          if(index !== -1) {
            chosen.splice(index, 1);
          }
          port.pods.forEach(pod => {
            if(pod.chosen){
              const index2 = otherChosen.findIndex(ch => ch == pod.id);
              if(index2 !== -1) {
                otherChosen.splice(index2, 1);
                pod.chosen = 0;
              }
            }
            pod.bills_of_lading.forEach(bl => {
              if(bl.chosen){
                const index3 = this.chosenBLs.findIndex(ch => ch == bl.id);
                if(index3 !== -1) {
                  this.chosenBLs.splice(index3, 1);
                  bl.chosen = 0;
                }
              }
            });
          });
        }
        this.setChosenPOLPODs();
        this.checkForDrafts();
      },

      setChosenPODs(e, port, upperPol){
        this.setChosenPOLPODs();
        const chosen = this.reversePortOrder ? this.chosenPols : this.chosenPods;
        const otherChosen = this.reversePortOrder ? this.chosenPods : this.chosenPols;
        const polAbove = this.reversePortOrder ? port.id: upperPol.id;
        this.allPorts = 0;
        if(e.target.checked){
          upperPol.chosen = upperPol.pods.find(pod => !pod.chosen) ? 0 : 1;
          if(!upperPol.hasOne){
            upperPol.hasOne = true;
          }
          port.bills_of_lading.forEach(bl => {
            if(!bl.chosen){
              bl.chosen = 1;
              this.chosenBLs.push(bl.id);
            }
          });
        }
        else{
          const index = chosen.findIndex(ch => ch == port.id);
          if(index !== -1) {
            chosen.splice(index, 1);
          }
          if(index !== -1 || chosen.length == 0){
            port.bills_of_lading.forEach(bl => {
              if(bl.chosen){
                const index2 = this.chosenBLs.findIndex(ch => ch == bl.id);
                if(index2 !== -1) {
                  this.chosenBLs.splice(index2, 1);
                  bl.chosen = 0;
                }
              }
            });
          }
          let allPODsUnselected = true;
          for(let port of upperPol.pods){
            if(port.chosen){
              allPODsUnselected = false;
              break;
            }
          }
          if(allPODsUnselected){
            const index = otherChosen.findIndex(ch => ch == polAbove);
            if(index !== -1) {
              otherChosen.splice(index, 1);
            }
          }
          upperPol.chosen = 0;
          upperPol.hasOne = upperPol.pods.find(pod => pod.chosen) ? 1 : 0;
        }
        this.checkForDrafts();
      },

      setSelectedBL(upperPol, upperPod, bl){
        this.selectedBL = bl;
        this.selectedBL.pod = this.reversePortOrder ? upperPol.id: upperPod.id;
        this.selectedBL.pol = this.reversePortOrder ? upperPod.id: upperPol.id;
        this.selectedBL.podItem = this.reversePortOrder ? upperPol: upperPod;
        this.selectedBL.polItem = this.reversePortOrder ? upperPod: upperPol;
      },

      setChosenBLs(e, upperPol, upperPod, bl){
        this.setChosenPOLPODs();
        const chosen = this.chosenBLs;
        const chosenAbove = this.reversePortOrder ? this.chosenPols : this.chosenPods;
        const chosenTop = this.reversePortOrder ? this.chosenPods : this.chosenPols;
        const podAbove = this.reversePortOrder ? upperPol.id: upperPod.id;
        const polAbove = this.reversePortOrder ? upperPod.id: upperPol.id;
        this.allPorts = 0;
        if(e.target.checked){
          chosen.push(bl.id);
          upperPod.chosen = upperPod.bills_of_lading.find(bl => !bl.chosen) ? 0 : 1;
          if(!upperPod.chosen){
            upperPod.hasOne = true;
          }
          upperPol.chosen = upperPol.pods.find(pod => !pod.chosen) ? 0 : 1;
          if(!upperPol.hasOne){
            upperPol.hasOne = true;
          }
        }
        else{
          const index = chosen.findIndex(ch => ch == bl.id);
          if(index !== -1){
            chosen.splice(index, 1);
            let allBLsUnselected = true;
            for(let otherBL of upperPod.bills_of_lading){
              if(otherBL.chosen){
                allBLsUnselected = false;
                break;
              }
            }
            if(allBLsUnselected){
              const index = chosenAbove.findIndex(ch => ch == podAbove);
              if(index !== -1) {
                chosenAbove.splice(index, 1);
                let allPODsUnselected = true;
                for(let port of upperPol.pods){
                  if(port.chosen){
                    allPODsUnselected = false;
                    break;
                  }
                }
                if(allPODsUnselected){
                  const index2 = chosenTop.findIndex(ch => ch == polAbove);
                  if(index2 !== -1)
                    chosenTop.splice(index2, 1);
                }
              }
            }
          }
          upperPod.chosen = 0;
          upperPol.chosen = 0;
          upperPol.hasOne = upperPol.pods.find(pod => pod.chosen) ? 1 : 0;
        }
        this.checkForDrafts();
      },
      setChosenPOLPODs(){
        const polObj = {}, podObj = {}, vpodObj = {};
        const ports = this.reversePortOrder ? this.chosenPortsReverse : this.chosenPorts;
        ports.forEach(pol => {
          pol.pods.forEach(pod => {
            pod.bills_of_lading.forEach(bl => {
              if(bl.chosen){
                polObj[pol.id] = 1;
                podObj[pod.id] = 1;
                vpodObj[bl.vpod] = 1;
              }
            });
          });
        });
        this.chosenPols = Object.keys(polObj);
        this.chosenPods = Object.keys(podObj);
        this.chosenVPods = Object.keys(vpodObj);
      },
      resetSelection(){
        const ports = this.reversePortOrder ? this.chosenPortsReverse : this.chosenPorts;
        ports.forEach(pol => {
          pol.chosen = 0;
          pol.pods.forEach(pod => {
            pod.chosen = 0;
            pod.bills_of_lading.forEach(bl => {
              bl.chosen = 0;
            });
          });
        });
        this.chosenPols = [], this.chosenPods = [], this.chosenBLs = [];
        this.allPorts = 0;
        this.checkForDrafts();
      },
      reverseSelection(e){
        const ports = this.reversePortOrder ? this.chosenPortsReverse : this.chosenPorts;
        this.chosenPols = [], this.chosenPods = [], this.chosenBLs = [];
        this.allPorts = 1;
        ports.forEach(pol => {
          pol.chosen = pol.chosen ? 0 : 1;
          if(pol.chosen) this.chosenPols.push(pol.id);
          pol.pods.forEach(pod => {
            pod.chosen = pod.chosen ? 0 : 1;
            if(pol.chosen && pod.chosen) this.chosenPods.push(pod.id);
            pod.bills_of_lading.forEach(bl => {
              bl.chosen = bl.chosen ? 0 : 1;
              if(pol.chosen && pod.chosen && bl.chosen) this.chosenBLs.push(bl.id);
            });
          });
        });
        this.checkForDrafts();
      },
      selectTransitBLs(option){
        this.resetSelection();
        const ports = this.reversePortOrder ? this.chosenPortsReverse : this.chosenPorts;
        ports.forEach(pol => {
          pol.pods.forEach(pod => {
            pod.bills_of_lading.forEach(bl => {
              bl.chosen = bl.transit == option ? 1 : 0;
              if(bl.chosen) this.chosenBLs.push(bl.id);
            });
            pod.chosen = pod.bills_of_lading.find(bl => bl.chosen == 0) == null ? 1 : 0;
          });
          pol.chosen = pol.pods.find(pod => pod.chosen == 0) == null ? 1 : 0;
        });
        this.checkForDrafts();
      },

      updateAllCounts(){
        const ports = this.reversePortOrder ? this.chosenPortsReverse : this.chosenPorts;
        ports.forEach(pol => {
          pol.pods.forEach(pod => {
            pod.approved_count = pod.bills_of_lading.filter(bl => bl.supervisor_approval_id == 4).length;
            pod.request_count = pod.bills_of_lading.filter(bl => bl.supervisor_approval_id == 2).length;
          });
          pol.approved_count = pol.pods.reduce((prev, el) => prev + el.approved_count, 0);
          pol.request_count = pol.pods.reduce((prev, el) => prev + el.request_count, 0);
        });
        this.checkForDrafts();
      },
      checkForDrafts(){
        const parent = this.$parent.$parent.$parent;
        parent.chosenBLLength = this.chosenBLs.length;
        let draftCount = 0;
        let unnapprovedCount = 0;
        this.hasSaywayBill = false;
        for(let id of this.chosenBLs){
          if(this.allBLs[id].bill_of_lading_status_id < 3)
            draftCount++;
          if(this.allBLs[id].supervisor_approval_id < 4)
            unnapprovedCount++;
          if(this.allBLs[id].bill_of_lading_status_id == 3 && this.allBLs[id].bl_type == 'Sea Waybill')
            this.hasSaywayBill = true;
        }
        if(draftCount || unnapprovedCount)
          parent.disableOriginal(draftCount, unnapprovedCount);
        else
          parent.enableOriginal();
      },

      setPortOrder(){
        this.reversePortOrder = !this.reversePortOrder;
        let buttonIds = [];
        if(this.reversePortOrder){
          for(let pol of this.chosenPortsReverse){
            for(let pod of pol.pods){
              for(let bl of pod.bills_of_lading){
                bl.chosen = this.chosenBLs.includes(bl.id) ? 1 : 0;
              }
              pod.chosen = pod.bills_of_lading.find(bl => !bl.chosen) ? 0 : 1;
            }
            pol.chosen = pol.pods.find(pod => !pod.chosen) ? 0 : 1;
          }
          for(let pol of this.chosenPorts){
            for(let pod of pol.pods){
              if(pod.expanded){
                buttonIds.push('btn-'+pod.id+'-'+pol.id);
                pod.expanded = false;
              }
            }
          }
        }
        else{
          for(let pol of this.chosenPorts){
            for(let pod of pol.pods){
              for(let bl of pod.bills_of_lading){
                bl.chosen = this.chosenBLs.includes(bl.id) ? 1 : 0;
              }
              pod.chosen = pod.bills_of_lading.find(bl => !bl.chosen) ? 0 : 1;
            }
            pol.chosen = pol.pods.find(pod => !pod.chosen) ? 0 : 1;
          }
          for(let pol of this.chosenPortsReverse){
            for(let pod of pol.pods){
              if(pod.expanded){
                buttonIds.push('btn-'+pod.id+'-'+pol.id);
                pod.expanded = false;
              }
            }
          }
        }
        this.$nextTick(() => {
          buttonIds.forEach((id) => document.getElementById(id).click());
        });
      },

      setManifestTypes(type, format){
        this.manifestType = type;
        this.formatType = format;
      },

      setAccepted(bl, pol, pod){
        const prevStatusId = (bl.supervisor_approval_id + 1) - 1;
        const newApprovalId = bl.supervisor_approval_id != 4 ? 4 : 1;
        bl.supervisor_approval_id = newApprovalId;
        bl.bill_of_lading_status_id = bl.supervisor_approval_id == 4 ? 3 : bl.bill_of_lading_status_id;
        if(newApprovalId != 4 && prevStatusId == 4 && bl.bill_of_lading_status_id == 3){
          bl.bill_of_lading_status_id = 2;
          if(this.selectedBL && this.selectedBL.id == bl.id) this.selectedBL.bill_of_lading_status_id = 2;
        }
        voyageManifestService.sendStatusRequest(bl.id, {'supervisor_approval_id': newApprovalId, 'supervisor_comment': '', 'nmtcompany_comment': ''}).then(response => {
          if(this.selectedBL && this.selectedBL.id == bl.id){
            this.autoUpdateOverview(newApprovalId, bl.bill_of_lading_status_id, prevStatusId, this.selectedBL.podItem, this.selectedBL.polItem, this.selectedBL);
            this.$emit('check', newApprovalId);
            bl.name = response.data.name;
          }
          else{
            this.autoUpdateOverview(newApprovalId, bl.bill_of_lading_status_id, prevStatusId, pod, pol, bl);
          }
        }).catch(error => console.log('error', error) );
      },

      setStatusRequest(){
        const prevStatusId = parseInt(this.selectedBL.supervisor_approval_id);
        this.sendingRequest = true;
        return voyageManifestService.sendStatusRequest(this.selectedBL.id, {'supervisor_approval_id': this.statusData.tabType, 
                                                                            'supervisor_comment': this.statusData.supervisorComments, 
                                                                            'nmtcompany_comment': this.statusData.nmtComments}).then(response => {
          this.selectedBL.supervisor_approval_id = this.statusData.tabType;
          this.selectedBL.bill_of_lading_status_id = this.selectedBL.supervisor_approval_id == 4 ? 3 : this.selectedBL.bill_of_lading_status_id;
          this.selectedBL.has_comments = this.statusData.supervisorComments || this.statusData.nmtComments;
          this.selectedBL.supervisor_approved = this.statusData.tabType == 4;
          this.selectedBL.name = response.data.name;
          if(this.statusData.tabType != 4 && this.selectedBL.bill_of_lading_status_id == 3){
            this.selectedBL.bill_of_lading_status_id = 2;
          }
          if(this.statusData.supervisorComments)
            this.selectedBL.supervisor_comment = this.statusData.supervisorComments;
          if(this.statusData.nmtComments)
            this.selectedBL.nmtcompany_comment = this.statusData.nmtComments;
          this.sendingRequest = false;
          this.$toast.success(response.data.message);
          this.autoUpdateOverview(this.tabType, this.selectedBL.bill_of_lading_status_id, prevStatusId, this.selectedBL.podItem, this.selectedBL.polItem, this.selectedBL);
        }).catch(error => {
          console.log('error', error);
        });
      },

      autoUpdateOverview(tabType, statusId, prevStatusId, pod, pol, bl){
          if(tabType == 2 && prevStatusId != 2){
            pod.request_count++;
            pol.request_count++;
          }
          if(tabType != 2 && prevStatusId == 2){
            pod.request_count--;
            pol.request_count--;
          }
          if(tabType != 4 && prevStatusId == 4){
            pod.approved_count--;
            pol.approved_count--;
          }
          if(tabType == 4 && prevStatusId != 4){
            pod.approved_count++;
            pol.approved_count++;
          }
          if(this.allBLs[bl.id]) {
            this.allBLs[bl.id].supervisor_approval_id = tabType;
            this.allBLs[bl.id].bill_of_lading_status_id = statusId;
          }
          this.checkForDrafts();
      },

      publish(e, firstPort, secondPort, forcePublish = false){
        const port1 = this.reversePortOrder ? secondPort : firstPort;
        const port2 = this.reversePortOrder ? firstPort : secondPort;
        const value = forcePublish ? 'published' : e.target.value;
        const isPublished = value == 'published';
        const name = port2 ? (port1.name + ' - ' + port2.name) : port1.name;
        const isDisabled = port2.approved_count < port2.bl_count || port2.loaded_count < port2.bl_count;
        if(isPublished && isDisabled && !forcePublish){
          e.preventDefault();
          document.getElementById("warning-dialog").showModal();
          this.forcePublish = 0;
          let amount = port2.bl_count - port2.approved_count;
          this.forcePublishData.amount = amount + ' ' + this.$tc('navigation.bills_of_lading', amount);
          this.forcePublishData.voyageport = this.voyage.name + ', ' + name;
          this.forcePublishData.pol = firstPort;
          this.forcePublishData.pod = secondPort;
          this.forcePublishData.bls = port2.bills_of_lading.filter(bl => bl.supervisor_approval_id != 4 && bl.bill_of_lading_status_id != 3);
          return;
        }
        if((isPublished && !forcePublish && !confirm(this.$t('manifest.confirm_publish', [name])))){
          e.preventDefault();
          return;
        }
        let publishDate = null;
        let publishUser = null;
        if(isPublished){
          publishDate = moment().format('YYYY-MM-DD');
          publishUser = store.getters.userId;
        }
        let publishInfo = isPublished ? (this.setPublishedDateString(null) + ' - ' + store.getters.getName) : null;
        if(port2){
          let commstatus = this.voyage.voyage_port_statuses.find(cs => ((cs.port_of_loading_id == port1.id || cs.port_of_loading_id === null) && cs.port_of_discharge_id == port2.id) && cs.office_id == this.operatedByOfficeId);
          if(commstatus){
            commstatus.status = value;
            commstatus.published_by_user_id = publishUser;
            commstatus.published_date = publishDate;
            port2.status = value;
            port2.publish_info = publishInfo;
            let allPodsArePublished = true;
            if(isPublished){
              port1.pods.forEach(port => {
                if(port.status != 'published'){
                  allPodsArePublished = false;
                }
              });
              if(allPodsArePublished){
                port1.publish_info = publishInfo;
                port1.status = 'published';
              }
            }
            const reversePorts = this.reversePortOrder ? this.chosenPorts : this.chosenPortsReverse;
            const rpol = reversePorts.find(rport => rport.id == secondPort.id);
            const rpod = rpol.pods.find(rport => rport.id == firstPort.id);
            rpod.status = value;
            rpod.publish_info = publishInfo;
          }
        }
        else{
          let commstatuses = this.voyage.voyage_port_statuses.filter(cs => (cs.status != 'published' && (cs.port_of_loading_id == port1.id || cs.port_of_loading_id === null)) && cs.office_id == this.operatedByOfficeId);
          commstatuses.forEach(commstatus => {
            commstatus.status = value;
            commstatus.published_by_user_id = publishUser;
            commstatus.published_date = publishDate;
            port1.publish_info = publishInfo;
            port1.status = value;
            port1.pods.forEach(port => {
              if(port.status != 'published'){
                port.publish_info = publishInfo;
                port.status = value;
              }
            });
          });
        }
        this.voyage.ports[port1.idcode].BLsByNumber.forEach(bl => {
          if(port2 === null || port2.code === bl.code){
            bl.status = value;
          }
        });
        voyageManifestService.updateOpenClosed(this.voyage.id, {'lines': this.voyage.voyage_port_statuses, 'force_bls': forcePublish ? this.forcePublishData.bls.map(bl => bl.id) : false}).then(response => {
          console.log('OK closing', response);
          if(forcePublish){
            this.forcePublishData.bls.forEach(bl => {
              bl.bill_of_lading_status_id = 3;
              bl.supervisor_approval_id = 4;
              bl.supervisor_approved = true;
              bl.force_final = true;
              bl.name = response.data.bl_numbers[bl.id];
              if(this.allBLs[bl.id]){
                this.allBLs[bl.id].bill_of_lading_status_id = bl.bill_of_lading_status_id;
                this.allBLs[bl.id].supervisor_approval_id = bl.supervisor_approval_id;
              }
            });
            this.updateAllCounts();
          }
        }).catch(error => console.log('error', error));
      },
      unpublish(firstPort, secondPort){
        const port1 = this.reversePortOrder ? secondPort : firstPort;
        const port2 = this.reversePortOrder ? firstPort : secondPort;
        if(confirm("Are you sure you want to unpublish " + port1.name + (port2 ? (" - " + port2.name) : "") + "?")){
          this.publish({target: {value: "open"}}, firstPort, secondPort);
        }
      },
      openBL(bl){
        if(bl.job_id){
          document.getElementById("job-dialog").showModal();
          document.getElementById('job-popup').children[0].classList.add('show', 'd-block');
          window.BLToOpen = bl.name;
          this.$refs.jobRef.reset(true);
          jobService.show(bl.job_id).then(response => {
            window.tabToOpen = response.data.bookings.findIndex(booking => booking.id == bl.booking_id);
            this.jobItemData = response.data;
            this.$refs.jobRef.setData(response.data);
          });
        }
        else{
          document.getElementById("bl-dialog").showModal();
          this.$refs.blRef.reset(true);
          blService.showWithJobData(bl.id).then(response => {
            this.blItemData = response.data;
            this.$refs.blRef.setData(response.data);
            this.$refs.blRef.active(1);
          });
        }
      },
      saveBL(triggerDownload){
        const ref = this.$refs.blRef;
        if(!ref.validate()) return false;
        const bl = ref.billOfLading;
        const autoApprove = store.getters.getCompanyHq || bl.can_approve_finalize_own || bl.operated_by_office_id == store.getters.getCompanyId;
        if(autoApprove){
          bl.supervisor_approval_id = bl.bill_of_lading_status_id == 3 ? 4 : 1;
        }
        return blService.update(bl).then((response) => {
          this.selectedBL.type = ref.billOfLading.bl_type;
          this.selectedBL.bill_of_lading_status_id = Number(ref.billOfLading.bill_of_lading_status_id);
          this.selectedBL.supervisor_approval_id = Number(ref.billOfLading.supervisor_approval_id);
          this.selectedBL.name = response.data.number;
          if(autoApprove){
            this.selectedBL.supervisor_approval_id = this.selectedBL.bill_of_lading_status_id == 3 ? 4 : 1;
            this.selectedBL.supervisor_approved = this.selectedBL.bill_of_lading_status_id == 3;
          }
          this.selectedBL.customer = ref.billOfLading.customer ? ref.billOfLading.customer : ref.billOfLading.customer_name;
          if(this.allBLs[ref.billOfLading.id]){
            this.allBLs[ref.billOfLading.id].bill_of_lading_status_id = this.selectedBL.bill_of_lading_status_id;
            this.allBLs[ref.billOfLading.id].supervisor_approval_id = this.selectedBL.supervisor_approval_id;
          }
          if(triggerDownload[0])
            return ref.$refs.billofladingref.downloadPDF(['bill-of-lading', response.data.id, response.data.number]);
          else
            document.getElementById("bl-dialog").close();

          this.updateAllCounts();
        });
      },

      openStatusModel(){
        document.getElementById("status-dialog").showModal();
        this.statusData = {
          tabType: this.selectedBL.supervisor_approval_id,
          supervisorComments: this.selectedBL.supervisor_comment,
          nmtComments: this.selectedBL.nmtcompany_comment
        };
      }
    }
  };
</script>

<style scoped>
table, #status-dialog, #warning-dialog{
  font-size: 0.9rem !important
}
.published{
  padding-top: 2px !important;
  padding-bottom: 3px !important;
}
.fa-chevron-right{
  transition: rotate 0.15s linear
}
section > label{
  max-width: 1373px
}
table{
  width: 100% !important
}
thead{
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 1px 1px #eee
}
table > tr{ border-bottom: 1px solid #eee }
tr{ height: 30px; border-bottom: 1px solid #eee }
thead > tr{ height: 40px }
td:nth-child(1){ width: 3rem; padding-left: 1rem }
td:nth-child(2){ width: 16rem }
td:nth-child(3){ width: 16rem }
td:nth-child(4){ width: 6rem }
td:nth-child(5){ width: 7rem }
td:nth-child(6){ width: 16rem; max-width: 16rem }
td:nth-child(7){ width: 6rem }
td:nth-child(8){ width: 8rem }
td:nth-child(9){ width: 9rem }
.hoverable:not(.bg-approved):hover{
  background-color: #f0f0f0;
}
.hoverable.bg-approved:hover{
  background-color: #c9dcb4;
}
tr:not(:hover) > td > a.show-on-hover:not(.show-on-click){
  display: none
}
.bg-approved{
  background-color: #d5e9c0;
}
.bg-declined{
  background-color: #e6b9c0;
}
.btn-close:not(.btn-black){
  position: relative;
  bottom: 4px;
  filter: invert();
  box-shadow: none !important;
}
#status-dialog, #bl-dialog, #warning-dialog, #job-dialog{
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  padding: 0
}
#status-dialog{
  background-color: #ebf6ff;
  background-clip: padding-box;
  min-width: 80rem;
}
#bl-dialog, #bl-dialog{
  min-width: 1400px;
}
#warning-dialog{
  width: 625px;
}
.warning-header{
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  font-family: 'DM Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}
</style>